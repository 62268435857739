import React, { useCallback, useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Skeleton,
	Box,
	Pagination,
	darken,
} from "@mui/material/";
import { TableCellProps, tableCellClasses } from "@mui/material/TableCell";
import styled from "styled-components";
import colors from "../../../ui/colors";

import { getData } from "../../../apis/data.api";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

type DataClient = {
	Canales: string[];
	CarpetasLength: number;
	Cliente: string;
	LatestDate: string;
	NombreCliente: string;
	_id: string;
};

type ClientsDataType = {
	results: DataClient[];
	totalPages: number;
	page: number;
};
interface ClientsTableProps {
	CompanySelected: (clientsName: string, folder: string) => void;
	DateRange: string[];
	Filter: string;
	searchTable: string | undefined;
}
enum Filters {
	"Name" = "NombreCliente",
	"Folder" = "CarpetasLength",
	"DateMinus" = "LatestDate",
	"DatePlus" = "LatestDate",
}
enum SortOrders {
	"Minus" = "desc",
	"Plus" = "asc",
}

export const ClientsTableMui: React.FC<ClientsTableProps> = ({
	CompanySelected,
	DateRange,
	Filter,
	searchTable = "",
}) => {
	if (searchTable && searchTable.length > 3) {
	}

	const token = useSelector((state: RootState) => state.auth.token);
	const [page, setPage] = useState<number>(1);
	const [clientsData, setClientsData] = useState<ClientsDataType | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const getClientsData = useCallback(async () => {
		try {
			setIsLoading(true);
			let dateRangeParam;
			if (DateRange[0] !== "None" && DateRange[1] !== "None") {
				dateRangeParam = `${DateRange[0]}0101-${DateRange[1]}1231`;
			}
			let encodedTerm: string | undefined = undefined;
			if (searchTable && searchTable.length > 3) {
				encodedTerm = searchTable.replace(/ /g, "%");
			}

			let sortField: "NombreCliente" | "LatestDate" | "CarpetasLength" =
				"LatestDate";
			let sortOrder: "asc" | "desc" = "desc";

			if (Filter in Filters) {
				switch (Filter) {
					case "Name":
						sortField = Filters.Name;
						sortOrder = SortOrders.Plus;
						break;
					case "Folder":
						sortField = Filters.Folder;
						break;
					case "DateMinus":
						sortField = Filters.DateMinus;
						sortOrder = SortOrders.Plus;
						break;
					case "DatePlus":
						sortField = Filters.DatePlus;
						sortOrder = SortOrders.Minus;
						break;
				}
			}

			const Data = await getData(token as string, {
				page: page,
				sortField: sortField,
				sortOrder: sortOrder,
				...(dateRangeParam ? { dateRange: dateRangeParam } : {}),
				...(encodedTerm ? { term: encodedTerm } : {}),
			});
			setClientsData(Data);
		} catch (error) {
			console.error("Error fetching client data:", error);
		} finally {
			setIsLoading(false);
		}
	}, [DateRange, Filter, token, page, searchTable]);

	useEffect(() => {
		if (!searchTable || searchTable.length >= 3) {
			getClientsData();
		}
	}, [getClientsData, page, searchTable]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const getCanalColor = (canal: string) => {
		switch (canal) {
			case "Verde":
				return `${colors.green}`;
			case "Rojo":
				return `${colors.red}`;
			case "Amarillo":
				return `${colors.yellow}`;
			case "Naranja":
				return `${colors.orange}`;
			default:
				return `${colors.primaryGreen}`;
		}
	};

	if (!clientsData || isLoading) {
		return (
			<TableWrap>
				<Paper elevation={0} sx={{ maxWidth: "97%" }}>
					<TableContainer
						component={Paper}
						elevation={0}
						style={{ maxHeight: "500", border: "none" }}
					>
						<Table aria-label="ClientsTable" sx={{ background: colors.white }}>
							<TableHead>
								<TableRow>
									<StyledTableCellEmpty>
										Últimos movimientos
									</StyledTableCellEmpty>
									<StyledTableCellEmpty></StyledTableCellEmpty>
									<StyledTableCellEmpty></StyledTableCellEmpty>
									<StyledTableCellEmpty></StyledTableCellEmpty>
									<StyledTableCellEmpty></StyledTableCellEmpty>
									<StyledTableCellEmpty></StyledTableCellEmpty>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array(11)
									.fill(0)
									.map((_, index) => (
										<TableRow key={index}>
											<StyledTableCell>
												<Skeleton variant="text" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" />
											</StyledTableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</TableWrap>
		);
	}
	const handleNombreClienteClick = (CompanyName: string) => {
		CompanySelected(CompanyName, "");
	};
	return (
		<>
			<Box>
				<TableWrap>
					<Paper elevation={0} sx={{ maxWidth: "97%" }}>
						<StyledTableContainer sx={{ maxHeight: 650 }}>
							<Table
								sx={{ background: colors.white }}
								aria-label="ClientsTable"
							>
								<TableHead>
									<TableRow>
										<StyledTableCellEmpty>
											Últimos movimientos
										</StyledTableCellEmpty>
										<StyledTableCellEmpty></StyledTableCellEmpty>
										<StyledTableCellEmpty></StyledTableCellEmpty>
										<StyledTableCellEmpty></StyledTableCellEmpty>
										<StyledTableCellEmpty></StyledTableCellEmpty>
										<StyledTableCellEmpty></StyledTableCellEmpty>
									</TableRow>
								</TableHead>
								<TableBody>
									{clientsData &&
										clientsData.results.map((data, index) => (
											<TableRow
												key={index}
												style={{
													fontFamily: "Poppins",
													fontStyle: "normal",
													fontWeight: 500,
													fontSize: "0.75rem",
													lineHeight: "1rem",
													letterSpacing: "0.198851px",
													color: colors.deepGreen,
												}}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<StyledTableCell align="left" scope="row">
													<button
														onClick={() => handleNombreClienteClick(data._id)}
														style={{
															background: "none",
															border: "none",
															cursor: "pointer",
															color: "inherit",
															textDecoration: "underline",
														}}
													>
														{data.NombreCliente}
													</button>
												</StyledTableCell>
												<StyledTableCell align="center">
													Carpetas: {` ${data.CarpetasLength}`}
												</StyledTableCell>
												<StyledTableCell align="center">
													{data.Cliente}
												</StyledTableCell>
												<StyledTableCell align="center">
													{data.Canales && data.Canales.length > 0 ? (
														Array.from(new Set(data.Canales as string[]))
															.filter(Boolean)
															.map((canal, index, array) => {
																const displayCanal = (() => {
																	switch (canal.trim()) {
																		case "V":
																			return "Verde";
																		case "R":
																			return "Rojo";
																		case "A":
																			return "Amarillo";
																		case "N":
																			return "Naranja";
																		default:
																			return canal;
																	}
																})();
																return (
																	<React.Fragment key={index}>
																		<span
																			style={{
																				color: getCanalColor(displayCanal),
																			}}
																		>
																			{displayCanal}
																		</span>
																		{index < array.length - 1 && ", "}
																	</React.Fragment>
																);
															})
													) : (
														<span style={{ color: getCanalColor("") }}>
															Sin canales
														</span>
													)}
												</StyledTableCell>
												<StyledTableCell align="center">
													{" - - -"}
												</StyledTableCell>
												<StyledTableCell align="center">
													{data.LatestDate}
												</StyledTableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</StyledTableContainer>
					</Paper>
				</TableWrap>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					marginTop: 2,
				}}
			>
				<Pagination
					sx={{
						"& .MuiPaginationItem-page.Mui-selected": {
							backgroundColor: colors.primaryGreen,
							color: "white",
							"&:hover": {
								backgroundColor: darken(colors.primaryGreen, 0.1),
							},
						},
					}}
					count={clientsData?.totalPages || 1}
					page={page}
					onChange={handleChangePage}
				/>
			</Box>
		</>
	);
};

export const TableWrap = styled.div`
	width: 96%;
	height: 100%;
	padding-left: 2.5%;
`;

interface StyledTableCellProps extends TableCellProps {
	textColor?: string;
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
	({ theme, ...props }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: colors.white,
			color: colors.primaryGreen,
			fontFamily: "Montserrat",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "0.75rem",
			lineHeight: "1rem",
			letterSpacing: "0.198519px",
			textAlign: "center",
		},
		[`&.${tableCellClasses.body}`]: {
			alignItems: "center",
			textAlign: "center",
			fontFamily: "Poppins",
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "0.75rem",
			lineHeight: "1rem",
			letterSpacing: "0.198851px",
			color: props.textColor || colors.deepGreen,
			borderBottom: `1px solid ${colors.primaryGreen}`,
		},
	})
);

const StyledTableCellEmpty = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.white,
		color: colors.deepGreen,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "1rem",
		lineHeight: "1.5rem",
		letterSpacing: "0.198519px",
		borderBottom: "1px solid",
	},
}));

const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		background: ${colors.white};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 20px;
		border: 3px solid ${colors.white};
	}
`;
