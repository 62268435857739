import styled from "styled-components";
import "./App.css";
import Router from "./routes/Router";
import colors from "../src/ui/colors";

const AppDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100vw;

	background-color: ${colors.primaryGreen};
	@media (min-width: 1920px) {
		max-width: 1920px;
		background-color: ${colors.primaryGreen};
		min-height: 720px;
		margin: 0 auto;
		width: 100%;
	}
`;

function App() {
	return (
		<AppDiv>
			<Router />
		</AppDiv>
	);
}

export default App;
