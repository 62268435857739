import { Grid } from "@mui/material";
import DelcarMotos from "../../assets/images/DelcarMotos.png";
import Hyundai from "../../assets/images/Hyundai.png";
import Solanas from "../../assets/images/Solanas.png";
import Stiler from "../../assets/images/Stiler.png";
import GrupoFiancar from "../../assets/images/grupoFiancar.png";
import Anep from "../../assets/images/Logos/anep.png";
import Musacco from "../../assets/images/Logos/musacco.png";
import Bastion from "../../assets/images/Logos/Bastion.png";
import Cala from "../../assets/images/Logos/calaDivolpe.png";
import LaAnyta from "../../assets/images/Logos/LaAnyta.png";
import BioKrop from "../../assets/images/Logos/BioKrop.png";
import DLA from "../../assets/images/Logos/DLA.png";
import Aperam from "../../assets/images/Logos/aperam.png";
import Milo from "../../assets/images/Logos/Milo.png";
import SendFex from "../../assets/images/Logos/Senfex.png";
import Ford from "../../assets/images/Logos/pngwing 1.png";
import Rusty from "../../assets/images/Logos/rusty.png";
import Gas from "../../assets/images/Logos/g4s 1.png";
import Misushi from "../../assets/images/Logos/misushi 1.png";
import Nordex from "../../assets/images/Logos/image 34.png";
import Rotunda from "../../assets/images/Logos/image 27.png";
import Saudu from "../../assets/images/Saudu.png";
import Sadia from "../../assets/images/Logos/image 29.png";
import tb from "../../assets/images/Logos/tb 1.png";
import TecnoMax from "../../assets/images/Logos/image 36.png";
import YarTech from "../../assets/images/Logos/yartech 1.png";
import Prontometal from "../../assets/images/Logos/image 35.png";
import lanafil from "../../assets/images/Lanafil1.png";

import styled from "styled-components";
import colors from "../colors";

const StyledLogoWrapperDiv = styled.div`
  display: flex;
  align-items: center;
  height: 6rem;
`;

const StyledLogoImg = styled.img`
  width: 8rem;
`;

const StyledLogosH2 = styled.h2`
  font-size: 3rem;
  color: ${colors.deepGreen};
  margin: 3rem 0;
  text-align: center;
`;

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const StyledTrustInUsDiv = styled.div`
  padding: 5rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem 9rem;
  @media (max-width: 1200px) {
    margin: 4rem 3rem;
  }
  @media (max-width: 800px) {
    margin: 4rem 2rem;
    padding: 3rem;
  }
  @media (max-width: 500px) {
    margin: 4rem 1rem;
    padding: 1rem;
  }
`;

export const LandingLogos: React.FC = () => {
  return (
    <StyledTrustInUsDiv>
      <StyledLogosH2>Confían en nosotros</StyledLogosH2>
      <StyledGrid container spacing={7}>
        <Grid spacing={1} item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Anep} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Musacco} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Bastion} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={GrupoFiancar} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Cala} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={LaAnyta} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Aperam} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Milo} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={DelcarMotos} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={BioKrop} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={SendFex} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={DLA} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Ford} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Rusty} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Gas} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Hyundai} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={lanafil} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Solanas} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Misushi} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Nordex} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Stiler} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Rotunda} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Saudu} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Sadia} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={tb} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={TecnoMax} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={YarTech} />
          </StyledLogoWrapperDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <StyledLogoWrapperDiv>
            <StyledLogoImg src={Prontometal} />
          </StyledLogoWrapperDiv>
        </Grid>
      </StyledGrid>
    </StyledTrustInUsDiv>
  );
};
